<template>
  <apexchart v-if="this.chartOptions.theme.monochrome.color && this.series.length > 0"
             width="100%"
             :height="pieChartHeight"
             :options="chartOptions"
             :series="series">
  </apexchart>
</template>

<script>

export default {
  name: 'PieChart',
  props: ['pieChartSeries', 'pieChartHeight', 'companyPrimaryColor'],
  watch: {
    $props: {
      handler() {
        this.chartOptions.theme.monochrome.color = this.companyPrimaryColor;

        if(this.pieChartSeries.length > 0) {
          for(var c = 0; c < this.pieChartSeries[0].data.length; c++) {          
            this.series.push(this.pieChartSeries[0].data[c].y);
            this.chartOptions.labels.push(this.pieChartSeries[0].data[c].x);
          }
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          width: '100%'         
        },
        labels: [],
        theme: {
          monochrome: {
            enabled: true,
            color: null,
          }
        },
        plotOptions: {
          pie: {
            dataLabels: { offset: -5 }
          }
        },
        dataLabels: {
          style: {
            fontSize: '12px',
            fontFamily: 'QuickSand, sans-serif',
            fontWeight: 400              
          },
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name]
          }
        },
        legend: {
          show: false
        }        
      }    
    }
  }
}
</script>
