<template>
  <CRow>
    <CCol cols="12" lg="12" class="pb-0 pt-0">
      <b-table class="data_table"
              :data="teamData"
              :striped="true"
              :hoverable="true"
              :mobile-cards="true"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"                 
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize">
        
        <template slot-scope="props">
          <b-table-column field="team_name" sortable :label="$t('common.Team')" :searchable="searchEnabled" width="20%">
            <span><b>{{props.row.team_name}}</b></span>          
          </b-table-column>
          <b-table-column field="department_name" sortable :label="$t('common.Department')" width="20%">
            <span>{{ props.row.department_name }}</span>
          </b-table-column>
          <b-table-column field="employees" sortable :label="$t('common.Employees')" width="20%">
            <div class="d-flex align-items-center count">
              <span><i class="icon top far fa-user"/> {{ props.row.employees }}</span>
            </div>            
          </b-table-column>          
          <b-table-column field="team_managers" :label="$t('common.Executives')" :searchable="searchEnabled" :sortable="false" width="15%">
            <div v-if="props.row.team_managers.length > 0" class="user_managers d-flex align-items-center">
              <userProfilePopover ref="userProfilePopover"
                                  v-for="manager in props.row.team_managers"
                                  v-bind:key="manager.user_id_external"
                                  mode="user"
                                  :userName="manager.name"
                                  :userProfileImage="manager.profile_image"
                                  :userIdExternal="manager.user_id_external"
                                  :senderIdExternal="null"
                                  popoverPlacement="right"/>
            </div>
            <span v-else>-</span>
          </b-table-column>          
          <b-table-column field="total_points" sortable :label="$t('common.Points')" width="15%">
            <div class="d-flex align-items-center credits">
              <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
              <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
              <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
              <span>{{props.row.total_points}} {{props.row.total_points !== 1 ? $t('common.points') : $t('common.point')}}</span>
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="text-center">
            <span>{{ $t('insights.No_team_points_recieved') }}</span>
          </div>                
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'TeamsTable',
  props: ['teams', 'environmentTag'],
  components: {
    userProfilePopover
  },  
  watch: {
    $props: {
      handler() {
        if(this.teams) {
          this.teamData = Object.values(this.teams);
          // Check if the pagination should be activated
          (this.teamData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      teamData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      searchEnabled: false,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
    }
  }
}
</script>
